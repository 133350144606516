/* Hide scrollbar for Chrome, Safari and Opera */
.scrollGallery::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollGallery {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  scroll-snap-type: x mandatory;
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
}

html::-webkit-scrollbar-track {
  display: none;
  background-color: #ffffff00;
  border-radius: 10px;
}

html::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f500;
}

html::-webkit-scrollbar-thumb {
  border-radius: 10px;

  height: 10px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.04, rgb(35 217 231)), color-stop(0.72, rgb(30 174 232)), color-stop(0.86, rgb(26 115 232)));
}

html {
  overflow: overlay;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
* {
  -webkit-tap-highlight-color: transparent;
}
@media all and (display-mode: standalone) {
  .installbutton {
    display: none !important;
  }
}
html,
body,
#root {
  height: 100%;
  display: flex;
    width: 100%;
    flex-direction: column;
    background-color: rgb(244, 246, 248);
}
@media (orientation: landscape) {
  .nolandscape {
    display: flex !important;
  }
  body,html, #root {
    overflow: hidden;
  }
}

/*CALENDAR */
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 1px solid rgb(248, 244, 244);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 0.3em;
  border-bottom: 1px solid rgb(248, 244, 244);
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  text-transform: capitalize;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.58em;
  color: #757575;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #404040;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #b7b7b7 !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
  text-transform: capitalize;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  color: #404040;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  color: rgb(30, 174, 232);
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  color: rgb(30, 174, 232);
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: rgb(26, 115, 232);
  color: white !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgb(30, 174, 232);
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__navigation__label__labelText--from {
  font-size: 0.9rem;
}

.loginForm .MuiInputLabel-formControl {
  color: #354767;
}

.loginForm .MuiInputBase-input {
  color: #354767;
}

.loginForm fieldset {
  border-color: #82828294;
}

.loginForm .MuiOutlinedInput-root fieldset:hover {
  border-color: #8f8f8f94;
}

/* .menuTab .Mui-selected {
  background-color: #1eaee8;
  color: #fff !important;
} */
a,
a:link,
a:visited {
  color: inherit
}

a.logout,
a.logout:link,
a.logout:visited {
  -webkit-text-decoration: underline !important;
  text-decoration: underline !important;
}

.details::before {
  content: attr(data-content-start);
  text-overflow: ellipsis;
}

.details::after {
  content: attr(data-content-end);
  text-overflow: clip;
  direction: rtl;
}

.details::before,
.details::after {
  display: inline-block;
  max-width: 50%;
  overflow: hidden;
  white-space: pre;
}

input {
  font-size: 16px !important;
  padding: 9px !important;
}

.pigeon-attribution {
  display: none;
}

/* div[dir=ltr]{
  border-bottom:1px solid rgb(194 194 194);
} */
/* .networkTable .material-icons-round,.networkTable  .MuiButton-outlined {
  color: #6706de !important; 
} */

.profitsBar .Mui-selected {
  color: #4CAF50 !important;
}

count-down#count :host(time) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  font-weight: bold;
}

count-down#count :host(.item) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5em;
}

count-down#count :host(.number) {
  color: #3498db;
}

count-down#count :host(.unit) {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #7f8c8d;
}


.ptr-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #aaa;
  z-index: 10;
  text-align: center;
  height: 50px;
  transition: all;
}

.ptr-element .genericon {
  opacity: 0.6;
  font-size: 34px;
  width: auto;
  height: auto;
  transition: all 0.25s ease;
  transform: rotate(90deg);
  margin-top: 5px;
}
.ptr-refresh .ptr-element .genericon {
  transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
  display: none;
}

.loading {
  display: inline-block;
  text-align: center;
  opacity: 0.4;
  margin: 12px 0 0 5px;
  display: none;
}
.ptr-loading .loading {
  display: block;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  transform: scale(0.3);
  border-radius: 50%;
  animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
  animation-delay: 0 !important;
}

.loading-ptr-2 {
  animation-delay: 0.2s !important;
}

.loading-ptr-3 {
  animation-delay: 0.4s !important;
}

@keyframes ptr-loading {
  0% {
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
  transform: translate3d(0, 30px, 0);
}
